<template>
  <div class="section">
    <div>
      <b-field>
        <p class="subtitle">{{localExam.title}}</p>
      </b-field>
      <b-field>
          <p>{{ localExam.description }}</p>
      </b-field>
      <hr />
    </div>
    <div v-for="(question, index) in localExam.questions" :key="question.id">
      <UserExamPreviewQuestion :indexQ="index" :question="question" :showCorrectAnswer="showCorrectAnswer"></UserExamPreviewQuestion>
    </div>
    <div class="columns m-4">
      <div class="column is-half" v-if="localExam.gates">
        <p class="subtitle">{{t('exam.gates')}}</p>
        <div v-for="(grade, index) in localExam.gates" :key="index">
          <p v-if="index < localExam.gates.length - 1">
            {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}}
            {{ localExam.gates[index + 1].requiredPoints }}% - {{t('exam.gradeLower')}}
            {{ grade.grade }}
          </p>
          <p v-else>
            {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}} 100% - {{t('exam.gradeLower')}} {{ grade.grade }}
          </p>
        </div>
      </div>
      <div class="column is-half">
        <p>
          {{t('grade.infoPoint')}}: {{ pointQuestions }}/
          {{ maxPointQuestions }}
        </p>
        <p>{{t('grade.infoPercent')}}: {{ percentGrade }}</p>
        <p class="subtitle">{{t('exam.grade')}}: {{ localExam.grade }}</p>
      </div>
    </div>
    
    <b-loading v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import UserExamPreviewQuestion from "./UserExamPreviewQuestion";

export default {
  name: "UserExamPreview",
  mixins: [CommonHelpers],
  components: {
    UserExamPreviewQuestion
  },

  props: {
    exam: { type: Object, required: true },
    showCorrectAnswer: { type: Boolean, required: false },
  },

  data: function () {
    return {
      localExam: {},
      isLoading: false,
    };
  },

  mounted() {
    this.localExam =  JSON.parse(JSON.stringify(this.exam));
  },

  watch: {},

  computed: {
    percentGrade() {
      if (this.maxPointQuestions > 0) {
        return Math.ceil((this.pointQuestions / this.maxPointQuestions) * 100);
      } else {
        return 0;
      }
    },

    pointQuestions() {
      let points = 0;
      if (this.localExam.questions) {
        this.localExam.questions.forEach((question) => {
          points += question.points;
        });
      }
      return points;
    },

    maxPointQuestions() {
      let points = 0;
      if (this.localExam.questions) {
        this.localExam.questions.forEach((question) => {
          points += question.maxPoints;
        });
      }
      return points;
    }
  },

  methods: {
  },
};
</script>

<style scoped>
</style>