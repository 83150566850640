<template>
  <div style="position: relative">
    <div
      class="card p-3"
      style="
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        z-index: 3;
        min-width: 8rem;
      "
    >
      <div class="mb-3">
        <template v-if="isCombo">
        <b-button expanded @click="openQuiz" icon-left="eye" size="is-small">{{t('exam.showTest')}}</b-button>
        <b-button expanded v-if="isTeacher && userExam.isFinished && !userExam.isGraded" type="is-primary" icon-left="star" size="is-small" @click="setQuizGrade"
          >{{t('grade.gradeTest')}}</b-button
        >
        </template>
        <div v-if="isTeacher && userExam.isFinished && !userExam.isGraded">
          <b-field v-if="isCombo">
            {{t('grade.pointTest')}}: {{quizPointQuestions}} / {{maxPointQuestions}}
          </b-field>
          <b-field >
             {{t('grade.pointImage')}}: {{userExam.points}} / {{userExam.maxPoints}}
          </b-field>
          <b-button expanded @click="showInfo" size="is-small" type="is-info">{{t('grade.showInfo')}}</b-button>
          <hr />

          <b-field>
            
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form>
              <ValidationProvider
                rules="required"
                v-slot="{ errors, valid }"
              >
                <b-field
                :label="t('grade.pointExamImage')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0])"
                >
                  <b-numberinput
                    controls-position="compact"
                    controls-alignment="right"
                    v-model="userExam.points"
                    size="is-small"
                    min="0"
                    :max="userExam.maxPoints"
                    expanded
                  />                    
                </b-field>
              </ValidationProvider>
              <b-button
                      size="is-small"
                      :label="t('grade.graduate')"
                      :disabled="invalid"
                      @click="confirmSaveGrade"
                      expanded
                      type="is-success"
                    />
            </form>
          </ValidationObserver>
          </b-field>
          
        </div>
      </div>
      <hr />
      <!-- <div v-if="images" class="mb-3">
        <p>Obrazy:</p>
        <b-field v-for="item in images" :key="item.index" class="mb-1">
          <b-radio-button
            expanded
            :native-value="item"
            size="is-small"
            v-model="currentLayer"
            >{{ item }}</b-radio-button
          >
        </b-field>
      </div> -->
      <b-field expanded :label="t('deepzoom.image')" label-position="on-border">
        <b-select
          expanded
          size="is-small"
          :placeholder="t('common.choose')"
          v-model="currentImage"
          @input="imageChanged"
        >
          <option v-for="item in images" :key="item.id" :value="item">
            {{ item.id }}
          </option>
        </b-select>
        
      </b-field>
      <div v-if="currentImage" class="mb-3">
        <b-field
          v-for="item in currentImage.layers"
          :key="item.index"
          class="mb-1"
        >
          <b-radio-button
            expanded
            :native-value="item"
            size="is-small"
            v-model="currentLayer"
            >{{ item.title }}</b-radio-button
          >
        </b-field>
      </div>
      <!-- <b-button
        icon-left="pencil"
        @click="switchEditor"
        size="is-small"
        expanded
        :type="{ 'is-info': editorMode, 'is-primary': !editorMode }"
      >
        <span v-if="editorMode">Tryb pokazu</span>
        <span v-else>Tryb edycji</span> 
      </b-button>-->
    </div>
    <!-- OPENSEADRAGON VIEWER -->
    <div
      id="openseadragon"
      style="width: 100%; height: calc(100vh - 50px)"
    ></div>
    <div style="display: none">
      <button id="mockupButton0"></button>
      <button id="mockupButton1"></button>
    </div>
    <b-modal v-model="isModalActive">
      <div v-if="isTeacher" class="modal-content exam-modal">
        <UserExamMentorPreview :exam="userExam" :showCorrectAnswer="true" />
      </div>
      <div class="modal-content exam-modal" v-else>
        <UserExamPreview :exam="userExam" />
      </div>
    </b-modal>
    <b-modal
      v-if="isTeacher"
      full-screen
      v-model="isModalGradeActive"
      :can-cancel="true"
    >
      <template #default="props">
        <UserExamGrade
          :exam="userExam"
          :showCorrectAnswer="true"
          @close="props.close"
          :gradute="true"
          @saveChange="savedChangeQuiz"
        />
      </template>
    </b-modal>
    <b-modal v-model="isInfoActive">
      <div class="columns m-4 box">
      <div class="column is-half" v-if="userExam.gates">
        <p class="subtitle">{{t('exam.gates')}}</p>
        <div v-for="(grade, index) in userExam.gates" :key="index">
          <p v-if="index < userExam.gates.length - 1">
            {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}}
            {{ userExam.gates[index + 1].requiredPoints }}% - {{t('exam.gradeLower')}}
            {{ grade.grade }}
          </p>
          <p v-else>
            {{t('exam.gatesFrom')}} {{ grade.requiredPoints }}% {{t('exam.gatesTo')}} 100% - {{t('exam.gradeLower')}} {{ grade.grade }}
          </p>
        </div>
      </div>
      <div class="column is-half">
        <p>
          {{t('grade.infoPoint')}}: {{ quizPointQuestions + userExam.points}}/
          {{ maxPointQuestions + userExam.maxPoints }}
        </p>
        <p>{{t('grade.infoPercent')}}: {{ percentGrade }}</p>
        <p class="subtitle">
          {{t('exam.grade')}}: {{ currentGrade }}
        </p>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import CommonMixins from "@/mixins/commons";
import { fabric } from "fabric";
import { Action } from "../../../store/config_actions";
//import ExamPreview from "@/components/userexamPreview";
import UserExamPreview from "./UserExamPreview";
import UserExamMentorPreview from "./UserExamPreviewMentor";
import UserExamGrade from "./UserExamSetGrade";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { SERVER } from '@/store/config_apis'

export default {
  name: "UserExamImageResult",
  mixins: [CommonMixins],
  components: {
    UserExamPreview,
    UserExamMentorPreview,
    UserExamGrade,
    ValidationProvider,
    ValidationObserver
  },

  data: function () {
    return {
      isModalActive: false,
      isModalGradeActive: false,
      isInfoActive:false,
      userExam: {},
      openSeaDragon: null,
      fabrics: null,
      zoomValue: 1,
      editorMode: false,
      imageObjects: [],
      newObject: {
        image: "",
        objects: [],
        canvasJson: "",
      },
      index: 0,
      images: [],
      currentImage: null,
      currentLayer: null,
      isLoading: false,
      sendData: null,
    };
  },

  props: {
    idUserExam: { type: String, required: true }
  },

  watch: {
     currentLayer(val) {
      if (this.currentImage) {
        let page = this.currentImage.layers.indexOf(val);

        if (page > -1) {
          this.goToLayer(page);
        }
      }
    },
  },

  mounted() {
    //this.openSeaInitialize();
    //this.getImages();
    this.getUserExam();
  },

  computed: {
    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    isStudent() {
      if (this.session && this.session.roles.includes("Student")) return true;
      else return false;
    },

    isCombo(){
      if(this.userExam){
        if(this.userExam.type === 2) return true;
        else return false;
      }
      else return null
    },

    currentJson() {
      if (this.userExam) {
        let currentShapes = this.userExam.shapeData.find(
          (shape) => shape.image === this.currentLayer.id
        );
        if (currentShapes) {
          return currentShapes.shapes;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    percentGrade() {
      if ((this.maxPointQuestions + this.userExam.maxPoints) > 0) {
        return Math.ceil(((this.quizPointQuestions + this.userExam.points)/ (this.maxPointQuestions + this.userExam.maxPoints)) * 100);
      } else {
        return 0;
      }
    },

    currentGrade() {
      if (this.userExam.gates && this.userExam.gates.length > 0) {
        for (let index = this.userExam.gates.length - 1; index > 0; index--) {
          const minGate = this.userExam.gates[index];

          if (this.percentGrade >= minGate.requiredPoints) {
            return minGate.grade;
          }
        }

        return 2.0;
      } else {
        return 0;
      }
    },

    maxPointQuestions() {
      let points = 0;
      if (this.userExam.questions) {
        this.userExam.questions.forEach((question) => {
          points += question.maxPoints;
        });
      }
      return points;
    },

    quizPointQuestions() {
      let points = 0;
      if (this.userExam.questions) {
        this.userExam.questions.forEach((question) => {
          points += question.points;
        });
      }
      return points;
    },
  },

  methods: {

    showInfo(){
      this.isInfoActive = !this.isInfoActive;
    },

    layerChanged(value) {
      this.openSeaInitialize(value);
    },

    setImages() {
      if (this.images.length > 0) {
        let imgs = [];

        this.images.forEach((img) => {
          imgs.push(`${SERVER}/api/Images/file/${img}.xml`);
          let imageObj = this.imageObjects.find(
            (object) => object.image == img
          );
          if (!imageObj) {
            let newObject = {
              image: img,
              objects: [],
              canvasJson: "",
            };
            this.imageObjects.push(newObject);
          }
        });

        this.currentLayer = this.images[0];

        this.openSeaInitialize(imgs);
      }
    },

    imageChanged(value) {
      this.currentImage = value;
      if (value.layers.length > 0) {

        let imgs = [];

        value.layers.forEach((img) => {
          imgs.push(`${SERVER}/api/DeepZoomConfig/Image/${value.id}/${img.id}.xml`)

          let imageObj = this.imageObjects.find(
            (object) => object.image == img.id
          );
          if (!imageObj) {
            let newObject = {
              image: img.id,
              objects: [],
              canvasJson: "",
            };
            this.imageObjects.push(newObject);
          }
        });

        this.currentLayer = value.layers[0];
        this.openSeaInitialize(imgs);
      }
    },

    goToLayer(index) {
      if (this.openSeaDragon) {
        this.canvas.remove(...this.canvas.getObjects());
        this.openSeaDragon.goToPage(index);
        if (this.currentJson) {
          this.canvas.loadFromJSON(
            this.currentJson,
            this.canvas.renderAll.bind(this.canvas)
          );
        }
      }
    },

    switchEditor() {
      this.editorMode = !this.editorMode;

      if (this.editorMode) {
        this.canvas.isDrawingMode = true;
        this.openSeaDragon.setMouseNavEnabled(false);
        this.openSeaDragon.outerTracker.setTracking(false);
      } else {
        this.canvas.isDrawingMode = false;
        this.openSeaDragon.setMouseNavEnabled(true);
        this.openSeaDragon.outerTracker.setTracking(true);
      }
    },

    getImages() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.IMAGES_GET_LIST)
        .then((response) => {
          this.isLoading = false;
          this.images = response;
        })
        .catch((error) => {
          this.mDangerSnack(error);
          this.isLoading = false;
        });
    },

    /**
     * OPENSEA
     * Initialize OpenSeaDragon component and configure events.
     */
    openSeaInitialize(image) {
      if (this.openSeaDragon === null) {
        // OpenSeaDragon init.
        // eslint-disable-next-line no-undef
        this.openSeaDragon = OpenSeadragon({
          id: "openseadragon",
          tileSources: image,
          defaultZoomLevel:1.05,
          showNavigationControl: false,
          showNavigator: true,
          sequenceMode: true,
          preserveViewport: true,
          nextButton: "mockupButton0",
          previousButton: "mockupButton1",
        });
      } else {
        this.canvas.remove(...this.canvas.getObjects());
        this.openSeaDragon.open(image);
      }

      // Initialize FabricsJS overlay
      this.fabrics = this.openSeaDragon.fabricjsOverlay({ scale: 1000 });

      // Get canvas instance
      this.canvas = this.fabrics.fabricCanvas();

      // Paint it red!
      this.canvas.freeDrawingBrush.color = "red";

      // Configure on path added event.
      //this.canvas.on("path:created", this.pathCreated);
    },

    /**
     * Event on SVG path created.
     */
    pathCreated(item) {
      var newPath = item.path;
      var arrayPath = newPath.path;
      var xStart = arrayPath[0][1];
      var ystart = arrayPath[0][2];
      var length = arrayPath.length;
      newPath.path[length - 1][1] = xStart;
      newPath.path[length - 1][2] = ystart;

      var text = new fabric.IText(this.t('exam.shapeTitle'), {
        left: xStart,
        top: ystart,
        fontSize: 12,
        fontFamily: "Verdana",
        fill: "black",
        visible: true,
      });

      this.canvas.remove(item.path);
      this.canvas.add(newPath);
      if (this.currentObjects) {
        this.currentObjects.objects.push({
          id: this.index,
          label: text.text,
          svg: newPath.toSVG(),
          path: newPath,
          orginal: item,
          text: text,
          x: xStart,
          y: ystart,
        });

        this.currentObjects.canvasJson = JSON.stringify(this.canvas.toJSON());
      }

      this.canvas.add(text).setActiveObject(text);
      text.enterEditing();
      text.hiddenTextarea.focus();
      this.index = this.index + 1;
    },

    deleteObjectExam(object) {
      if (object) {
        let baseObject = this.currentObjects.objects.find(
          (q) => q.id === object.id
        );
        if (baseObject) {
          this.canvas.remove(baseObject.path);
          this.canvas.remove(baseObject.text);
          this.currentObjects.objects.splice(
            this.curentObjects.objects.indexOf(baseObject),
            1
          );
          this.currentObjects.canvasJson = JSON.stringify(this.canvas.toJSON());
        }
      }
    },

    addCurrentObjectsToCanvas() {
      this.currentObjects.objects.forEach((element) => {
        this.canvas.add(element.path);
        this.canvas.add(element.text);
      });
    },

    saveChangesObjectExam(object) {
      if (object) {
        this.canvas.renderAll();
        this.currentObjects.canvasJson = JSON.stringify(this.canvas.toJSON());
      }
    },

    imagePanTo: function (x, y, height) {
      //TODO: scale x,y to openseadragon
      var imageScale = 1500;

      var targetX = x / imageScale;
      var targetY = (y - 50) / imageScale;
      var targetZoom = imageScale / height / 10;

      // eslint-disable-next-line no-undef
      this.openSeaDragon.viewport.panTo(
        // eslint-disable-next-line no-undef
        new OpenSeadragon.Point(targetX, targetY)
      );
      this.openSeaDragon.viewport.zoomTo(targetZoom);
    },

    showObject(object) {
      if (object) {
        let baseObject = this.currentObjects.objects.find(
          (q) => q.id === object.id
        );
        if (baseObject) {
          this.imagePanTo(
            baseObject.path.left,
            baseObject.path.top,
            baseObject.path.height
          );
        }
      }
    },

    openQuiz() {
      this.isModalActive = !this.isModalActive;
    },

    setQuizGrade() {
      this.isModalGradeActive = !this.isModalGradeActive
    },

    confirmSaveGrade(){
      if(this.userExam.type === 2){
        this.$buefy.dialog.confirm({
        title: this.t('grade.graduateTitle'),
        message: this.t('grade.graduateMessageCombo'),
        onConfirm: () => this.saveGrade(),
        type: "is-info",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
      }else{
        this.$buefy.dialog.confirm({
        title: this.t('grade.graduateTitle'),
        message: this.t('grade.graduateMessage'),
        onConfirm: () => this.saveGrade(),
        type: "is-info",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
      }

    },

    saveGrade(){
      this.userExam.grade = this.currentGrade;
      this.userExam.isGraded = true;
      this.updateUserExam();
    },

    savedChangeQuiz(){
      this.getUserExam();
    },

    /** Update UserExam */
    updateUserExam() {
      this.userExam.time = this.timeUser;
      this.$store
        .dispatch(Action.USEREXAM_UPDATE, this.userExam)
        .then(() => {
          this.mSuccessSnack(this.t('exam.sended'));
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          //this.isLoading = false;
        });
    },

    /** Get UserExam */
    getUserExam() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.USEREXAM_GET_EXAM_BY_ID, this.idUserExam)
        .then((payload) => {
          this.userExam = payload;
          // this.images = payload.images;
          this.isLoading = false;
          // this.setImages();
          this.getDeepZoomConfigForExamsImages(payload.images) 
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getDeepZoomConfigForExamsImages(idImages) {
      this.isLoading = true;
      let send = {images: idImages}
      this.$store
        .dispatch(Action.DEEPZOOM_CONFIG_GROUP,send)
        .then((payload) => {
          this.images = payload.images;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
        });
    },
  },
};
</script>

<style scoped>
.exam-modal {
  background-color: white;
}
</style>