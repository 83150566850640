<template>
  <div class="p-4">
    <div v-if="localQuestion">
      <b-field grouped>
        <b-field>
          <p class="subtitle">{{ indexQ + 1 }}. {{ localQuestion.title }}</p>
        </b-field>
        <b-field>
          <p>
            {{t('grade.awardedPoints')}}: {{ localQuestion.points }} /
            {{ localQuestion.maxPoints }}
          </p>
        </b-field>
      </b-field>
      <b-field>
        <p>{{ localQuestion.text }}</p>
      </b-field>
      <b-field v-if="localQuestion.answers.length > 0">
        <ul class="p-3">
          <li
            v-for="option in localQuestion.answers"
            :key="option.id"
            :class="{
              'text-sucess': option.isCorrect && showCorrectAnswer,
              'text-info': option.isChecked,
            }"
          >
            {{ option.text }}
          </li>
        </ul>
      </b-field>
      <b-field v-else>
        <b-input
          type="textarea"
          :placeholder="localQuestion.note"
          disabled
        ></b-input>
      </b-field>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";

export default {
  name: "UserExamPreviewQuestion",
  mixins: [CommonHelpers],
  components: {},

  props: {
    indexQ: { type: Number, required: true },
    question: { type: Object, required: true },
    showCorrectAnswer: { type: Boolean, required: false },
  },

  data: function () {
    return {
      localQuestion: null,
      editMode: false,
      tmpValue: null,
      isLoading: false,
    };
  },

  mounted() {
    this.localQuestion = JSON.parse(JSON.stringify(this.question));
  },

  methods: {},
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-info {
  text-decoration: underline;
}
.text-sucess {
  color: #006600;
}
</style>